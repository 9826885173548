import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import './misc';

// AG GRID KEY
import {LicenseManager} from 'ag-grid-enterprise';

LicenseManager.setLicenseKey(AG_GRID_LICENCE_KEY);

if (document.getElementById('builder')) {
    require(['react', 'react-dom', './builder/builder'], (React, ReactDOM, Builder) => {
        const element = document.getElementById('builder');

        ReactDOM.render(<Builder.default />, element);
    });
}

if (document.getElementById('master-chapter-builder')) {
    require(['react', 'react-dom', './master_chapter/master_chapter_builder'], (
        React,
        ReactDOM,
        MasterChapterBuilder,
    ) => {
        const element = document.getElementById('master-chapter-builder');

        ReactDOM.render(<MasterChapterBuilder.default />, element);
    });
}

if (document.getElementById('master-table')) {
    require(['react', 'react-dom', './master_table/master_table'], (React, ReactDOM, MasterTable) => {
        const element = document.getElementById('master-table');

        ReactDOM.render(<MasterTable.default />, element);
    });
}

if (document.getElementById('report')) {
    require(['react', 'react-dom', './report/report'], (React, ReactDOM, Report) => {
        ReactDOM.render(<Report.default />, document.getElementById('report'));
    });
}

if (document.getElementById('report_todos')) {
    require(['react', 'react-dom', './todos/report_todos'], (React, ReactDOM, ReportTodos) => {
        const element = document.getElementById('report_todos');
        const reportId = element.getAttribute('data-report');
        const archived = element.getAttribute('data-archived') == '1';
        ReactDOM.render(<ReportTodos.default reportId={reportId} archived={archived} />, element);
    });
}

if (document.getElementById('user_todos')) {
    require(['react', 'react-dom', './todos/user_todos'], (React, ReactDOM, UserTodos) => {
        const element = document.getElementById('user_todos');
        const userId = element.getAttribute('data-user');
        ReactDOM.render(<UserTodos.default userId={userId} />, element);
    });
}

if (document.getElementById('dashboard_todos')) {
    require(['react', 'react-dom', './todos/dashboard_todos'], (React, ReactDOM, DashboardTodos) => {
        const element = document.getElementById('dashboard_todos');
        const userId = element.getAttribute('data-user');
        ReactDOM.render(<DashboardTodos.default userId={userId} />, element);
    });
}

// @deprecated
if (document.getElementsByClassName('wysiwyg-viewer')) {
    const elements = document.getElementsByClassName('wysiwyg-viewer');
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        const value = element.getAttribute('data-value');

        require(['react', 'react-dom', './report/ui/common/wysiwyg_viewer'], (React, ReactDOM, WysiwygViewer) => {
            ReactDOM.render(<WysiwygViewer.default value={value} />, element);
        });
    }
}

if (document.getElementsByClassName('searchable-select')) {
    const elements = document.getElementsByClassName('searchable-select');
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        const name = element.getAttribute('data-name');
        const placeholder = element.getAttribute('data-placeholder');
        const value = element.getAttribute('data-value');

        const options = element.getAttribute('data-options') ? JSON.parse(element.getAttribute('data-options')) : null;

        require(['react', 'react-dom', './searchable_select/select'], (React, ReactDOM, SearchableSelect) => {
            ReactDOM.render(
                <SearchableSelect.default name={name} options={options} placeholder={placeholder} value={value} />,
                element,
            );
        });
    }
}

if (document.getElementsByClassName('lightbox')) {
    const elements = document.getElementsByClassName('lightbox');
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        const index = element.getAttribute('data-index');
        const type = element.getAttribute('data-type');

        require(['react', 'react-dom', './image_lightbox/image_lightbox'], (React, ReactDOM, ImageLightbox) => {
            ReactDOM.render(<ImageLightbox.default index={index} type={type} />, element);
        });
    }
}

if (document.getElementsByClassName('wysiwyg-text-editor')) {
    const elements = document.getElementsByClassName('wysiwyg-text-editor');
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        const value = element.getAttribute('data-value');
        const name = element.getAttribute('data-name');
        const height = element.getAttribute('data-height');
        const disableFullscreen = element.getAttribute('data-disable-fullscreen') === 'true';
        const enableMaxCharCount = element.getAttribute('data-enable-max-char-count') === 'true';

        require(['react', 'react-dom', './text_editor/wysiwyg_text_editor'], (React, ReactDOM, WysiwygTextEditor) => {
            ReactDOM.render(
                <WysiwygTextEditor.default
                    value={value}
                    name={name}
                    disableFullscreen={disableFullscreen}
                    enableMaxCharCount={enableMaxCharCount}
                    height={height}
                />,
                element,
            );
        });
    }
}

if (document.getElementsByClassName('wysiwyg-text-editor-img')) {
    const elements = document.getElementsByClassName('wysiwyg-text-editor-img');
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        const value = element.getAttribute('data-value');
        const name = element.getAttribute('data-name');
        const embedVideoEnabled = element.getAttribute('data-embed-video') === 'true';
        const disableFullscreen = element.getAttribute('data-disable-fullscreen') === 'true';
        const enableMaxCharCount = element.getAttribute('data-enable-max-char-count') === 'true';

        require(['react', 'react-dom', './text_editor/wysiwyg_text_editor_img'], (
            React,
            ReactDOM,
            WysiwygTextEditorImg,
        ) => {
            ReactDOM.render(
                <WysiwygTextEditorImg.default
                    value={value}
                    name={name}
                    disableFullscreen={disableFullscreen}
                    enableMaxCharCount={enableMaxCharCount}
                    embedVideoEnabled={embedVideoEnabled}
                />,
                element,
            );
        });
    }
}

if (document.getElementsByClassName('wysiwyg-text-editor-simple')) {
    const elements = document.getElementsByClassName('wysiwyg-text-editor-simple');
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        const value = element.getAttribute('data-value');
        const name = element.getAttribute('data-name');
        const disableFullscreen = element.getAttribute('data-disable-fullscreen') === 'true';
        const enableMaxCharCount = element.getAttribute('data-enable-max-char-count') === 'true';

        require(['react', 'react-dom', './text_editor/wysiwyg_text_editor_simple'], (
            React,
            ReactDOM,
            WysiwygTextEditorSimple,
        ) => {
            ReactDOM.render(
                <WysiwygTextEditorSimple.default
                    value={value}
                    name={name}
                    disableFullscreen={disableFullscreen}
                    enableMaxCharCount={enableMaxCharCount}
                />,
                element,
            );
        });
    }
}

if (document.getElementsByClassName('wysiwyg-text-editor-simple-img')) {
    const elements = document.getElementsByClassName('wysiwyg-text-editor-simple-img');
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        const value = element.getAttribute('data-value');
        const name = element.getAttribute('data-name');
        const disableFullscreen = element.getAttribute('data-disable-fullscreen') === 'true';
        const enableMaxCharCount = element.getAttribute('data-enable-max-char-count') === 'true';

        require(['react', 'react-dom', './text_editor/wysiwyg_text_editor_simple_img'], (
            React,
            ReactDOM,
            WysiwygTextEditorSimpleImg,
        ) => {
            ReactDOM.render(
                <WysiwygTextEditorSimpleImg.default
                    value={value}
                    name={name}
                    disableFullscreen={disableFullscreen}
                    enableMaxCharCount={enableMaxCharCount}
                />,
                element,
            );
        });
    }
}

if (document.getElementsByClassName('datepicker')) {
    const elements = document.getElementsByClassName('datepicker');
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        const value = element.getAttribute('data-value');
        const name = element.getAttribute('data-name');

        require(['react', 'react-dom', './datepicker/datepicker'], (React, ReactDOM, DatePicker) => {
            ReactDOM.render(<DatePicker.default value={value} name={name} />, element);
        });
    }
}

if (document.getElementsByClassName('dynamic-tooltip')) {
    const elements = document.getElementsByClassName('dynamic-tooltip');
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        const value = element.innerHTML;

        require(['react', 'react-dom', './dynamic_tooltip/dynamic_tooltip'], (
            React,
            ReactDOM,
            DynamicTooltipComponent,
        ) => {
            ReactDOM.render(<DynamicTooltipComponent.default value={value} />, element);
        });
    }
}

if (typeof REFRESH_CALLBACK !== 'undefined') {
    require(['./misc/refresh_callback'], function () {
        /* Noop */
    });
}
