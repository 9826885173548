/*
 |--------------------------------------------------------------------------
 | Misc JS, only paste scripts here if actually misc!
 |--------------------------------------------------------------------------
 */
import 'bootstrap-sass/assets/javascripts/bootstrap/dropdown';
import 'bootstrap-sass/assets/javascripts/bootstrap/modal';
import 'bootstrap-tagsinput';
import './misc/pin';
import './misc/file_uploader';
import './misc/tag';
import './organisation_property_list/organisation_property_list';
import {__} from './helpers';

/**
 * Bootstrap dropdown
 */
(function() {
    $('.dropdown-toggle').dropdown();
})();

/**
 * Click away status message
 */
(function() {
    $('#flash-message').bind('click', function() {
        $(this).addClass('fade-out');
    });
})();

/**
 * Image preview of PHP upload image
 */
(function() {
    const imageUploader = $('input[name="image"]');

    if (imageUploader.length > 0) {
        imageUploader.change(function() {
            const reader = new FileReader();
            reader.onload = function(e) {
                if (e.total > 2500000) {
                    return;
                }
                $('#uploadedimage').attr('src', e.target.result);
            };
            reader.readAsDataURL(this.files[0]);
        });
    }
})();

/**
 * Submit filter
 */
(function() {
    $('#filter select').bind('change', function() {
        $('#filter').submit();
    });
})();

/**
 * Show back to top and go to top
 */
(function() {
    function scrollHandle() {
        const e = $('.back-top-wrapper');
        const r = $('.report');

        let scrollTop = $(window).scrollTop();

        if (scrollTop > 90) {
            e.removeClass('hidden');
            e.addClass('fade-in');

            r.addClass('scrolled');
        } else {
            e.addClass('hidden');
            e.removeClass('fade-in');

            r.removeClass('scrolled');
        }
    }

    scrollHandle();

    $(window).scroll(function() {
        scrollHandle();
    });

    $('#back_top').bind('click', function() {
        $('html, body').animate(
            {
                scrollTop: 0,
            },
            500,
        );
    });
})();

/**
 * Toggle trend subline
 */
(function() {
    const btn = $('.trend button');
    if (btn.length > 0) {
        btn.bind('click', function() {
            let target = $(this).attr('target-line');
            if (target !== '') {
                $('#subline-' + target).toggleClass('hidden');
                $('#' + target).toggleClass('open');
            }
        });
    }
})();

/**
 * Toggle tags
 */
(function() {
    $('.tags-wrapper .icon').bind('click', function() {
        $(this)
            .closest('.tags-wrapper')
            .toggleClass('open');
    });
})();

/**
 * Show modal with error
 */
(function() {
    $(document).ready(function() {
        const modalName = document.getElementById('modalName');

        if (modalName) {
            let id = modalName.getAttribute('data-modal');
            if (id) {
                $('#' + id).modal('show');
            }
        }
    });
})();

/**
 * Scroll to today in Schedule
 */
(function() {
    const target = $('#today');

    if (target.length > 0) {
        $('html, body').animate(
            {
                scrollTop: $(target).offset().top - 50,
            },
            300,
        );
        return false;
    }
})();

/**
 * commentCharacterCount
 */
(function() {
    const textareas = $('textarea.has-counter');
    const maxLength = 255;

    for (let $i = 0; $i < textareas.length; $i++) {
        let textarea = $(textareas[$i]);
        let length = maxLength - textarea.val().length;

        let warning = $('<div class="validation green"></div>').html(
            __('reports.info.remaining_chars', {char_left: length}),
        );

        textarea.after(warning);
        textarea.keyup(function() {
            let length = $(this).val().length;
            length = maxLength - length;

            warning.html(__('reports.info.remaining_chars', {char_left: length}));
            length < 0 ? warning.attr('class', 'validation red') : warning.attr('class', 'validation green');

            let button = $(this)
                .closest('form')
                .find('button[type="submit"]');
            if (button.length && length < 0) {
                button.addClass('disabled');
            } else {
                button.removeClass('disabled');
            }
        });
    }
})();

/**
 * Disable on submit
 */
(function() {
    const button = $('[type="submit"]');

    if (button.length > 0) {
        button.bind('click', function() {
            const form = $(this).closest('form');

            if (!form.hasClass('no-loading')) {
                form.addClass('loading');
            }
        });
    }
})();

/**
 * Open comments in trend
 */
(function() {
    const buttons = $('.toggle-comments');

    if (buttons.length > 0) {
        buttons.bind('click', function() {
            const targetId = $(this).attr('data-target');

            if (targetId !== '') {
                const target = $('#' + targetId);
                const body = $('body');
                const comments = $('ul.comments');
                const row = $(this).closest('tr');
                const rows = $('tr');

                if ($(this).hasClass('open')) {
                    body.removeClass('fixed-modal-open');
                    comments.removeClass('hidden');
                    target.addClass('hidden');
                    rows.removeClass('open');
                    buttons.removeClass('open');
                } else {
                    body.addClass('fixed-modal-open');
                    comments.addClass('hidden');
                    target.removeClass('hidden');
                    rows.removeClass('open');
                    row.addClass('open');
                    buttons.removeClass('open');

                    $(this).addClass('open');
                }
            }
        });
    }
})();

/**
 * Close comments in trend
 */
(function() {
    const close = $('#close-comments');

    if (close.length > 0) {
        close.bind('click', function() {
            $('body').removeClass('fixed-modal-open');
            $('tr').removeClass('open');
            $('.btn').removeClass('open');
        });
    }
})();
/**
 * Remove page loader
 */
$(document).ready(function() {
    $('#page-loader').fadeOut(250);
});

/**
 * Toggle nav size
 */
(function() {
    $('#menu-toggle').bind('click', function() {
        $('body').toggleClass('folded-menu');
        $(this).addClass('nav__fold-toggle--disabled');
        setTimeout(function() {
            $('#menu-toggle').removeClass('nav__fold-toggle--disabled');
        }, 500);
    });
})();

/**
 * Toggle nav open
 */
(function() {
    const menuToggle = $('#menu-toggle');

    if (menuToggle.length > 0) {
        const url = menuToggle.attr('data-url');

        menuToggle.bind('click', function() {
            $.ajax({
                type: 'GET',
                url: url,
                data: {},
                dataType: 'json',
            });
        });
    }
})();

/**
 * Open broadcast message
 * Also, set listener on hide listener
 */
(function() {
    const broadcastMessageModals = $('#broadcast-active-message-modal');
    if (broadcastMessageModals.length > 0) {
        const broadcastIsAlreadySeen = broadcastMessageModals.attr('data-is-seen-by-user');
        const url = broadcastMessageModals.attr('data-dismiss-url');

        if (broadcastIsAlreadySeen === '0') {
            broadcastMessageModals.modal('show');
        }

        broadcastMessageModals.on('hidden.bs.modal', function() {
            $.ajax({
                type: 'GET',
                url: url,
                data: {},
                dataType: 'json',
            });
        });

        // Set button to open modal
        const navOpenBroadcastMsg = $('#nav-open-broadcast-message-modal');
        if (navOpenBroadcastMsg.length > 0) {
            navOpenBroadcastMsg.bind('click', function() {
                broadcastMessageModals.modal('show');
            });
        }
    }
})();

/**
 * Select books list from project_id
 */
(function() {
    const projectSelect = $('select#project_id');

    if (projectSelect.length > 0) {
        projectSelect.change(function() {
            let projectId = $(this).val();
            let url = '/ajax/projects/' + projectId + '/books';

            $.ajax({
                type: 'GET',
                url: url,
                data: {},
                dataType: 'json',
                success: function(result) {
                    let bookSelect = $('select#book_id');

                    let emptyOption = $('<option value=""></option>');
                    bookSelect.html('');
                    emptyOption.html(__('books.intro.please_select_book'));
                    bookSelect.append(emptyOption);

                    if (bookSelect.length > 0) {
                        for (const key in result) {
                            let option = $('<option></option>');
                            option.val(key);
                            option.html(result[key]);
                            bookSelect.append(option);
                        }
                    }
                },
            });
        });
    }
})();

(function() {
    const toggleChapters = $('.toggle-chapters');
    const buttonText = toggleChapters.text();

    if (toggleChapters.length > 0) {
        toggleChapters.bind('click', function() {
            const hiddenInput = $('input[name="chapters_open"]');
            const reportId = $(this).attr('data-id');
            const chapters = $('#chapters-' + reportId);

            if ($(this).hasClass('opened')) {
                chapters.addClass('hidden');
                hiddenInput.val(false);
                $(this)
                    .text(buttonText)
                    .removeClass('opened icon-chevron-up')
                    .addClass('icon-chevron-down');
            } else {
                chapters.removeClass('hidden');
                hiddenInput.val(true);
                $(this)
                    .text(__('reports.buttons.chapters_select'))
                    .removeClass('icon-chevron-down')
                    .addClass('opened icon-chevron-up');
            }
        });
    }
})();

(function() {
    const windowsScreenshotInfo = $('#screenshot-info-windows');
    const macScreenshotInfo = $('#screenshot-info-mac');

    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

    if (windowsScreenshotInfo.length > 0 && macScreenshotInfo.length > 0) {
        if (isMac === true) {
            windowsScreenshotInfo.addClass('hidden');
            macScreenshotInfo.removeClass('hidden');
        }
    }
})();

(function() {
    const lists = $('.organisation-property-list');

    if (lists.length > 0) {
        const button = $('.add-property-list-item');

        if (button.length > 0) {
            button.bind('click', function() {
                const parent = $(this).parent();

                const propertyItems = parent.find('.organisation-property-items');

                const organisationPropertyId = propertyItems.attr('data-target-id');
                const organisationPropertyPlaceholder = propertyItems.attr('data-target-placeholder');
                const deleteButtonText = propertyItems.attr('data-target-delete-button-text');

                const html = $('<div>', {
                    class: 'form-group has-handle',
                })
                    .append(
                        $('<div>', {
                            class: 'col-xs-8',
                        })
                            .append(
                                $('<div>', {
                                    class: 'form-group-handle icon-chevrons-expand-vertical',
                                }),
                            )
                            .append(
                                $('<input>', {
                                    class: 'form-control',
                                    name: 'organisation_property[' + organisationPropertyId + '][list][]',
                                    type: 'text',
                                    placeholder: organisationPropertyPlaceholder,
                                }),
                            ),
                    )
                    .append(
                        $('<div>', {
                            class: 'col-xs-4',
                        }).append(
                            $('<div>', {
                                class: 'pull-right',
                            }).append(
                                $('<button>', {
                                    class: 'button btn btn-danger icon-trash2 btn-delete-list-item',
                                    type: 'button',
                                })
                                    .on('click', function() {
                                        const formGroup = $(this).closest('.form-group');

                                        if (
                                            $(this)
                                                .closest('.organisation-property-items')
                                                .children().length > 1
                                        ) {
                                            formGroup.remove();
                                        } else {
                                            formGroup.find('.form-control').val('');
                                        }
                                    })
                                    .text(deleteButtonText),
                            ),
                        ),
                    );

                propertyItems.append(html);
            });
        }
    }
})();

(function() {
    const formSelect = $('.organisation-property-select-type');

    if (formSelect.length > 0) {
        formSelect.bind('change', function() {
            const parentRow = $(this).closest('.form-group');

            const changeCustomPropertyButton = $(parentRow).find('.btn-change-custom-property-list');

            if (changeCustomPropertyButton.length > 0) {
                changeCustomPropertyButton.toggle();
            }
        });
    }
})();

(function() {
    const deleteButton = $('.btn-delete-list-item');

    if (deleteButton.length > 0) {
        deleteButton.bind('click', function() {
            const formGroup = $(this).closest('.form-group');

            if (
                $(this)
                    .closest('.organisation-property-items')
                    .children().length > 1
            ) {
                formGroup.remove();
            } else {
                formGroup.find('.form-control').val('');
            }
        });
    }
})();

(function() {
    const counter = $('#redirect-counter');
    let timeleft = 5;

    if (counter.length > 0) {
        const timer = setInterval(function() {
            if (timeleft <= 0) {
                clearInterval(timer);
                window.location.href = '/login';
            } else {
                counter.html(timeleft);
            }
            timeleft -= 1;
        }, 1000);
    }
})();

/**
 * Custom ui-resizable for 2 columns
 * TODO: Add unique storage key when used elsewhere
 */
(function() {
    const uiResizableHandle = $('.ui-resizable-handle');
    const leftColumn = $('#ui-resizable-left');
    const rightColumn = $('#ui-resizable-right');
    const windowWidth = window.innerWidth;

    if (uiResizableHandle.length > 0) {
        const uiResizableRightWidth = localStorage.getItem('ui_resizable_right_width_' + windowWidth);
        const uiResizableLeftWidth = localStorage.getItem('ui_resizable_left_width_' + windowWidth);

        let offset = 0;

        if (uiResizableRightWidth && uiResizableLeftWidth) {
            rightColumn.width(parseFloat(uiResizableRightWidth));
            leftColumn.width(parseFloat(uiResizableLeftWidth));
        }

        uiResizableHandle.on('mousedown', function(e) {
            let initialClientX = e.clientX;

            $('body').addClass('no-selection');
            $(this).on('mousemove', function(event) {
                offset = initialClientX - event.clientX;

                const rightWidth = rightColumn.width() + offset;
                const leftWidth = leftColumn.width() - offset;
                rightColumn.width(rightWidth);
                leftColumn.width(leftWidth);

                localStorage.setItem('ui_resizable_right_width_' + windowWidth, rightWidth.toString());
                localStorage.setItem('ui_resizable_left_width_' + windowWidth, leftWidth.toString());

                initialClientX = event.clientX;
            });
        });

        $(document).on('mouseup', function() {
            uiResizableHandle.off('mousemove');
            $('body').removeClass('no-selection');
        });

        $(window).resize(function() {
            leftColumn.removeAttr('style');
            rightColumn.removeAttr('style');
            localStorage.removeItem('ui_resizable_right_width_' + windowWidth);
            localStorage.removeItem('ui_resizable_left_width_' + windowWidth);
        });
    }
})();
