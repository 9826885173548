import Sortable from 'sortablejs';

const elements = document.getElementsByClassName('organisation-property-items');
if (elements) {
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];

        Sortable.create(element, {
            handle: '.form-group-handle',
            animation: 150,
        });
    }
}
