import {__} from '../helpers';

$(document).ready(() => {
    $('input[data-role="user-tags"]').each(function() {
        const sync = () => {
            if ($(this).attr('data-project')) {
                $.post('/projects/' + $(this).attr('data-project') + '/tags/store', {
                    tags: $(this).val(),
                });
            }

            if ($(this).attr('data-book')) {
                $.post('/books/' + $(this).attr('data-book') + '/tags/store', {
                    tags: $(this).val(),
                });
            }

            if ($(this).attr('data-report-container')) {
                $.post('/report-containers/' + $(this).attr('data-report-container') + '/tags/store', {
                    tags: $(this).val(),
                });
            }
        };

        $(this).tagsinput();
        $(this).on('itemAdded', sync);

        $(this).on('itemRemoved', sync);
    });

    if ($('input[data-role="tagsinput"]').length > 0) {
        var placeholder = __('tag.placeholders.organisation_tags');
    }

    if ($('input[data-role="user-tags"]').length > 0) {
        var placeholder = __('tag.placeholders.add_personal_tags');
    }

    $('.bootstrap-tagsinput input').attr('placeholder', placeholder);
});
