$(document).ready(() => {
    const bind = (pin, disableEndpoint, enableEndpoint) => {
        pin.addClass('pin-initialized');
        pin.bind('click', e => {
            e.preventDefault();

            if (!pin.hasClass('pin-pending')) {
                pin.addClass('pin-pending');

                if (pin.hasClass('pinned')) {
                    pin.removeClass('pinned');
                    $.post(disableEndpoint)
                        .done(() => {
                            pin.removeClass('pin-pending');
                        })
                        .fail(() => {
                            pin.removeClass('pin-pending');
                            pin.addClass('pinned');
                        });
                } else {
                    pin.addClass('pinned');
                    $.post(enableEndpoint)
                        .done(() => {
                            pin.removeClass('pin-pending');
                        })
                        .fail(() => {
                            pin.removeClass('pin-pending');
                            pin.removeClass('pinned');
                        });
                }
            }
        });
    };

    $('[data-role="pin-project"]').each(function() {
        const enableEndpoint =
            '/organisations/' +
            $(this).attr('data-organisation') +
            '/projects/' +
            $(this).attr('data-project') +
            '/pin/enable';
        const disableEndpoint =
            '/organisations/' +
            $(this).attr('data-organisation') +
            '/projects/' +
            $(this).attr('data-project') +
            '/pin/disable';
        const pin = $(this);

        bind(pin, disableEndpoint, enableEndpoint);
    });

    $('[data-role="pin-book"]').each(function() {
        const enableEndpoint =
            '/organisations/' +
            $(this).attr('data-organisation') +
            '/books/' +
            $(this).attr('data-book') +
            '/pin/enable';
        const disableEndpoint =
            '/organisations/' +
            $(this).attr('data-organisation') +
            '/books/' +
            $(this).attr('data-book') +
            '/pin/disable';
        const pin = $(this);

        bind(pin, disableEndpoint, enableEndpoint);
    });

    $('[data-role="pin-report-container"]').each(function() {
        const enableEndpoint =
            '/organisations/' +
            $(this).attr('data-organisation') +
            '/reports/' +
            $(this).attr('data-report-container') +
            '/pin/enable';
        const disableEndpoint =
            '/organisations/' +
            $(this).attr('data-organisation') +
            '/reports/' +
            $(this).attr('data-report-container') +
            '/pin/disable';
        const pin = $(this);

        bind(pin, disableEndpoint, enableEndpoint);
    });
});
