import fileUploader from '../network/file_uploader.js';
import {__} from '../helpers';

$(document).ready(() => {
    $('[data-role="file"]').each(function() {
        const input = $(this);
        input.hide();

        const fileInput = $('input[type="file"][name="file"]');
        const progress = $('<span class="progress" style="margin-top:10px;">0%</span>');
        const submit = $('input[type="submit"]');
        input.after(progress);

        fileInput.each(function() {
            $(this).bind('change', async function() {
                const element = $(this);
                let file = null;

                if (element.length && element.get(0).files.length) {
                    file = element.get(0).files[0];
                }

                if (file !== null) {
                    const types = [
                        'image/jpeg',
                        'image/jpg',
                        'image/png',
                        'application/pdf',
                        'text/csv',
                        'application/msword',
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                        'application/vnd.ms-excel',
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'application/vnd.ms-powerpoint',
                        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                    ];

                    let isValidType = types.includes(file.type);
                    let isValidSize = file.size < 25000000;
                    let error = $('.validation');

                    if (isValidSize && isValidType) {
                        const uploader = new fileUploader(file);
                        uploader.onProgress(p => {
                            submit.addClass('disabled');
                            progress.removeClass('finished').html(Math.ceil(p * 100) + '%');
                            progress.append(
                                '<span class="ratio" style="width:' + Math.ceil(p * 100) + '%' + ';"></span>',
                            );

                            p > 0.45 ? progress.addClass('white') : '';
                        });

                        const filename = await uploader.start();

                        progress.html('100%').addClass('finished');
                        submit.removeClass('disabled');

                        input.attr('value', filename);

                        $('input[name="original_filename"]').attr('value', file.name);
                        $('input[name="original_mime"]').attr('value', file.type);
                        $('input[name="original_size"]').attr('value', file.size);

                        if (typeof filename !== typeof undefined && filename !== false) {
                            error.hide();
                        }
                    } else if (!isValidSize && !error.length) {
                        const error = $('<div class="validation warning"></div>').html(
                            __('validation.custom.attachment.max_file_uploader'),
                        );
                        element.closest('.input-group').after(error);
                    } else if (!isValidType && !error.length) {
                        const error = $('<div class="validation warning"></div>').html(
                            __('validation.custom.attachment.mimes_file_uploader'),
                        );
                        element.closest('.input-group').after(error);
                    }
                }
            });
        });
    });
});
