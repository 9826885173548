export function __(translationString, replacements) {
    if (!replacements) {
        replacements = {};
    }
    return Lang.get(translationString, replacements);
}

export function trans(translationString, replacements) {
    return __(translationString, replacements);
}

export function trans_plural(translationString, num, replacements) {
    if (!replacements || typeof replacements !== 'object') {
        replacements = {};
    }

    return Lang.choice(translationString, num, replacements);
}
