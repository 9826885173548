import {get, post} from 'fitted';

export default class fileUploader {
    constructor(file) {
        this.file = file;

        this.callbacks = {
            progress: [],
        };
    }

    onProgress(callback) {
        this.callbacks.progress.push(callback);
    }

    async start() {
        const authorization = await this._getAuthorizationFromServer();
        const location = await this._getUploadLocation(authorization.url, authorization.token);
        return await this._executeUpload(location, authorization.filename);
    }

    @get('/uploads/files/link')
    _getAuthorizationFromServer(request, response) {
        return request({}, response);
    }

    @post('{+url}')
    _getUploadLocation(url, token, request, response) {
        response.processor = result => {
            return {
                getBody: () => {
                    return result.headers.location;
                },
                isOk: () => {
                    return result.headers.location != undefined;
                },
            };
        };

        return request(
            {
                template: {
                    url: url,
                },
                headers: {
                    Authorization: 'Bearer ' + token,
                    'X-Upload-Content-Type': this.file.type,
                    'X-Upload-Content-Length': this.file.size,
                },
            },
            response,
        );
    }

    _reportProgress(progress) {
        this.callbacks.progress.forEach(callback => callback(progress));
    }

    _executeUpload(location, filename) {
        return new Promise((resolve, reject) => {
            /* Google requires us to do uploads in multiples of 262144 */
            const CHUNK_SIZE = 262144 * 5;

            /* Current chunking offset */
            let offset = 0;

            /* Request that is sending the file */
            const fileReader = new FileReader();

            /* Try to do the next chunk */
            const doChunk = () => {
                if (offset >= this.file.size) {
                    resolve(filename);
                    return;
                }

                this._reportProgress(offset / this.file.size);

                const slice = this.file.slice(offset, offset + CHUNK_SIZE);
                fileReader.readAsArrayBuffer(slice);
            };

            /* If the file reader has read something, try and upload it */
            fileReader.onload = e => {
                const sendFileRequest = new XMLHttpRequest();
                sendFileRequest.onreadystatechange = () => {
                    if (
                        sendFileRequest.readyState == 4 &&
                        (sendFileRequest.status == 308 || sendFileRequest.status == 200)
                    ) {
                        /* Continue to next chunk */
                        offset += CHUNK_SIZE;
                        doChunk();
                    }
                };

                const till = (offset + CHUNK_SIZE > this.file.size ? this.file.size : offset + CHUNK_SIZE) - 1;
                sendFileRequest.open('PUT', location, true);
                sendFileRequest.setRequestHeader('Content-Range', `bytes ${offset}-${till}/${this.file.size}`);
                sendFileRequest.send(e.target.result);
            };

            doChunk();
        });
    }
}
